import { RichTextFormatter } from '@/core/localization/translate';
import { translate, translateFormatter } from '@/domains/core/localization/translate';
import { footerLegalConsumptionInformationHrefB2B as realFooterLegalConsumptionInformationHrefB2B, footerLegalMentionsHrefB2B as realFooterLegalMentionsHrefB2B, footerLegalProtectionHref as realFooterLegalProtectionHref, footerLegalTermsHrefB2B as realFooterLegalTermsHrefB2B } from './modules/LegalData/translations';
export const footerLegalConsumptionInformationHrefB2B = realFooterLegalConsumptionInformationHrefB2B;
export const footerLegalMentionsHrefB2B = realFooterLegalMentionsHrefB2B;
export const footerLegalProtectionHref = realFooterLegalProtectionHref;
export const footerLegalTermsHrefB2B = realFooterLegalTermsHrefB2B;

// Contact Newsletter Footer/Modal
export const footerContactNewsletterCommon = {
  ...translate({
    "inputLabel": "Email address"
  }),
  legal: undefined
};
export const footerContactNewsletter = translate({
  "title": "Interested in some (great) advice and even better deals?",
  "subtitle": "Sign up for our newsletter!",
  "subtextLink": "Find out more"
});
export const footerContactNewsletterIncentiveVariant = translate({
  "title": "5\u20AC* for you, (good) advice and special offers!",
  "subtitle": "Subscribe to our newsletter!",
  "subtextLink": "Find out more"
});
export const footerContactSubtextIncentiveVariant = translateFormatter<{
  br: RichTextFormatter;
}>("We will only use your email address to send you our newsletter. <br></br>You can unsubcribe via the link in the newsletter.", undefined, undefined, "en");
export const footerContactSubtext = translateFormatter<{
  br: RichTextFormatter;
}>("We will only use your email address to send you our newsletter. <br></br>You can unsubcribe via the link in the newsletter.", undefined, undefined, "en");
// Contact Newsletter Success Modal
export const contactNewsletterModalSuccess = translate({
  "title": "Congratulations!",
  "subtitle": "Save money, save pounds on your DIY projects with our exclusive offers and top deals!"
});
export const contactNewsletterModalSuccessIncentiveVariant = translate({
  "title": "Congratulations!",
  "subtitle": "It's your turn to get the best deals and special offers! You'll be able to tinker with your wallet in peace"
});
export const {
  availablePlayStore,
  detailsApp,
  detailsProApp,
  downloadAppStore,
  footerAboutSubTitle,
  footerAboutTitle,
  footerAppStoreMamomanoProUrl,
  footerAppStoreMamomanoUrl,
  footerB2cConnectProLink,
  footerBestSellers,
  footerBestSellersHref,
  footerContactCountrySubTitle,
  footerContactCountryTitle,
  footerContactNewsletterInvalidError,
  footerContactNewsletterSubmitText,
  footerContactServiceCallPrice,
  footerContactServiceSubTitle,
  footerContactServiceTitle,
  footerContactSocialNetworkSubTitle,
  footerContactSocialNetworkTitle,
  footerContactSubTitle,
  footerContactTitle,
  footerManoExpress,
  footerManoExpressHref,
  footerManoExpressHrefB2B,
  footerNavigationAbout,
  footerNavigationAboutHref,
  footerNavigationAffiliation,
  footerNavigationAffiliationHref,
  footerNavigationBrands,
  footerNavigationBrandsHref,
  footerNavigationContactUs,
  footerNavigationContactUsHref,
  footerNavigationCouponLegalMention,
  footerNavigationCouponLegalMentionHref,
  footerNavigationHelp,
  footerNavigationHelpHref,
  footerNavigationOrders,
  footerNavigationOrdersHref,
  footerNavigationPressContact,
  footerNavigationPressContactHref,
  footerNavigationRecruitingSellers,
  footerNavigationRecruitingSellersHref,
  footerNavigationRecruitment,
  footerNavigationRecruitmentHref,
  footerPlaymarketMamomanoProUrl,
  footerPlaymarketMamomanoUrl,
  footerProButtonTitle,
  footerQualityPaymentTitle,
  footerReportContentDSALabel,
  footerReportContentDSALink,
  footerSitemapAdvice,
  footerSitemapAdviceHref,
  footerSitemapCurrentOffers,
  footerSitemapCurrentOffersHref,
  footerSitemapProductCategories,
  footerSitemapProductCategoriesHref,
  footerSitemapInspirations,
  footerSitemapInspirationsHref,
  footerSitemapTopProducts,
  footerSitemapTopProductsHref,
  footerToTopButtonAriaLabel,
  footerTrustProSubtitle,
  footerTrustProTitle,
  titleApp,
  titleProApp
} = translate({
  "availablePlayStore": "Get it on Google Play",
  "detailsApp": "+ 6 millions of products in your pockets",
  "detailsProApp": "*Our app for the professionals",
  "downloadAppStore": "Download on the App Store",
  "footerAboutSubTitle": "The Serious Bits",
  "footerAboutTitle": "Find Out More",
  "footerAppStoreMamomanoUrl": "https://apps.apple.com/gb/app/manomano/id1503142603",
  "footerAppStoreMamomanoProUrl": "https://apps.apple.com/gb/app/manomano-pro/id1498180724",
  "footerB2cConnectProLink": "/login?b2b=true",
  "footerBestSellers": "Best sellers",
  "footerBestSellersHref": "/bs",
  "footerContactCountrySubTitle": "Coming soon to planet Mars!",
  "footerContactCountryTitle": "International",
  "footerContactNewsletterInvalidError": "The email address you entered is not valid",
  "footerContactNewsletterSubmitText": "Sign Up",
  "footerContactServiceCallPrice": "Local network charges apply.",
  "footerContactServiceSubTitle": "ManoMano, at your service!",
  "footerContactServiceTitle": "Customer care",
  "footerContactSocialNetworkSubTitle": "The magic of the Internet, across the globe!",
  "footerContactSocialNetworkTitle": "Join the conversation!",
  "footerContactSubTitle": "Our enquiry corner",
  "footerContactTitle": "Information & Contact",
  "footerNavigationAbout": "Who are we?",
  "footerNavigationAboutHref": "https://about.manomano.com/en",
  "footerNavigationAffiliation": "Affiliate Program",
  "footerNavigationAffiliationHref": "https://info.manomano.com/join-our-program/",
  "footerNavigationBrands": "Our brands",
  "footerNavigationBrandsHref": "/brands",
  "footerNavigationContactUs": "Contact us",
  "footerNavigationContactUsHref": "/contact",
  "footerNavigationCouponLegalMention": "Terms and conditions of use of coupons",
  "footerNavigationCouponLegalMentionHref": "/lp/manomano-promo-codes-save-money-with-our-exclusive-offers-735",
  "footerNavigationHelp": "Need help?",
  "footerNavigationHelpHref": "/help",
  "footerNavigationOrders": "Track orders",
  "footerNavigationOrdersHref": "/orders",
  "footerNavigationPressContact": "Press Enquiries",
  "footerNavigationPressContactHref": "https://about.manomano.com/en/newsroom-uk/",
  "footerNavigationRecruitingSellers": "Become a seller",
  "footerNavigationRecruitingSellersHref": "/lp/sell-on-manomano-2620",
  "footerNavigationRecruitment": "Join Us",
  "footerNavigationRecruitmentHref": "https://careers.manomano.jobs/en",
  "footerPlaymarketMamomanoProUrl": "https://play.google.com/store/apps/details?id=com.manomano.pro&hl=en_GB&gl=gb",
  "footerPlaymarketMamomanoUrl": "https://play.google.com/store/apps/details?id=com.manomano&hl=en_GB&gl=gb",
  "footerProButtonTitle": "Go to your Pro account",
  "footerQualityPaymentTitle": "Secure payment",
  "footerReportContentDSALabel": "Report illicit content",
  "footerReportContentDSALink": "https://survey.zohopublic.eu/zs/Y2DHs5?lang=en",
  "footerSitemapAdvice": "Our advice",
  "footerSitemapAdviceHref": "/our-advice",
  "footerSitemapCurrentOffers": "Our current offers",
  "footerSitemapInspirations": "Our inspirations",
  "footerSitemapInspirationsHref": "/advice/our-inspirations-14917",
  "footerSitemapCurrentOffersHref": "/our-current-offers",
  "footerSitemapProductCategories": "The most popular product categories",
  "footerSitemapProductCategoriesHref": "/the-most-popular-product-categories",
  "footerSitemapTopProducts": "Our comparisons",
  "footerSitemapTopProductsHref": "/our-comparisons",
  "footerManoExpress": "Discover ManoExpress",
  "footerManoExpressHref": "/",
  "footerManoExpressHrefB2B": "/",
  "footerToTopButtonAriaLabel": "Go back up",
  "footerTrustProSubtitle": "Get your go-to brands at discount prices.",
  "footerTrustProTitle": "Are you a Pro?",
  "titleApp": "The ManoMano application",
  "titleProApp": "Discover the ManoManoPro application"
});